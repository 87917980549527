import * as React from 'react';
import Helmet from 'react-helmet';

import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';

import s from './AppLayout.scss';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

// tslint:disable no-default-export
export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      {/*<HeaderLink
        name="about"
        to="/about"
      />*/}

      {/*<HeaderLink
        name="github"
        to="https://github.com/ueno-llc"
        icon={<Github />}
      />*/}
    </Header>

    {children}

    <Footer
      logo={<div>COVID-19 Response</div>}
      social={[]}
    />

    {isDev && <Devtools />}
  </div>
);
